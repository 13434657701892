<template>
<b-card no-body>

    <b-card-header>
        <!-- title and subtitle -->
        <div>
            <b-card-title class="mb-1">
                {{$t('Status')}}
            </b-card-title>

        </div>
        <!--/ title and subtitle -->

    </b-card-header>

    <b-card-body>
        <vue-apex-charts type="pie" width="350" :options="chartOptions" :series="series" />
    </b-card-body>
</b-card>
</template>

    
<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BCard,
        VueApexCharts,
        BCardHeader,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        flatPickr,
    },
    props: {
        labels: {
            type: String,
            required: true,
        },
        values: {
            type: String,
            required: true,
        },

    },
    data() {
        return {
            series: this.values,
            chartOptions: {
                chart: {
                    width: 350,
                    type: 'pie',
                },
                labels: this.labels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: ['#28c76f', `#7367f0`, `#ff9f43`],
            },

        }
    },
}
</script>
