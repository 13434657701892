<template>
    <b-card no-body>
        
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
                {{$t('Level Fuel')}}
            </b-card-title>
            
          </div>
          <!--/ title and subtitle -->
    
        </b-card-header>
    
        <b-card-body>
            <vue-apex-charts type="bar" height="250" :options="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series" />
        </b-card-body>
    </b-card>
    </template>
    
    <script>
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    
    export default {
        components: {
            BCard,
            VueApexCharts,
            BCardHeader,
            BCardBody,
            BCardTitle,
            BCardSubTitle,
            flatPickr,
        },
        props: {
            axisX: {
                type: String,
                required: true,
            },
            axisY: {
                type: String,
                required: true,
            },
            
            
        },
        data() {
            return {
                rangePicker: ['2019-05-01', '2019-05-10'],
    
                lineAreaChartSpline: {
                    series: [{
                            name: '%',
                            data: this.axisY,
                        },
    
                    ],
                    chartOptions: {
                        chart: {
                            id: 'area-datetime',
                            type: 'area',
                            height: 250,
                            zoom: {
                                autoScaleYaxis: true
                            },
    
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            show: true,
                            curve: 'straight',
                        },
                        legend: {
                            show: false,
                            position: 'top',
                            horizontalAlign: 'left',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                        },
                        grid: {
                            xaxis: {
                                lines: {
                                    show: false,
                                },
                            },
                        },
                        xaxis: {
                            categories: this.axisX,
                            tickAmount: 6,
                        },
                        yaxis: {
                            // opposite: isRtl
                        },
                        fill: {
                            /*
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                opacityFrom: 0.7,
                                opacityTo: 0.9,
                                stops: [0, 100]
                            }
                            */
                        },
                        tooltip: {
                            shared: false,
                        },
                        colors: ['#015289'],
                    },
                },
                
            }
        },
    }
    </script>
    