<template>
  <b-card no-body>

    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ $t('Metrics') }}
        </b-card-title>

      </div>
      <!--/ title and subtitle -->

    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="bar" :options="chartOptions" :series="series"/>
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    labels: {
      type: String,
      required: true,
    },
    values: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [{data: this.values}],
      chartOptions: {
        chart: {type: "bar"},
        plotOptions: {
          bar: {horizontal: true}
        },
        dataLabels: {
          formatter: val => val + "%",
          background: {
            enabled: true,
            foreColor: "#fff"
          }
        },
        xaxis: {categories: this.labels},
        tooltip: {enabled: false}
      },
    }
  },
}
</script>
