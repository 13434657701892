<template>
  <b-card no-body>
    <b-card-header v-if="title">
      <b-card-title class="mb-1">{{ title }}</b-card-title>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="area" height="250"
                       :options="lineAreaChartSpline.chartOptions"
                       :series="lineAreaChartSpline.series"/>
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle,} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    title: {type: String},
    axisX: {
      type: Array,
      required: true,
    },
    axisY: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      lineAreaChartSpline: {
        series: [
          {
            name: this.unit,
            data: this.axisY,
          },
        ],
        chartOptions: {
          chart: {
            id: 'area-datetime',
            type: 'area',
            height: 250,
            zoom: {
              autoScaleYaxis: true
            },

          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: this.axisX,
            tickAmount: 6,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }

          },
          tooltip: {
            shared: false,
          },
          colors: ['#015289'],
        },
      },
    }
  },
}
</script>
    